export const UNI_LIST = ''
export const UNI_EXTENDED_LIST = ''
const UNI_UNSUPPORTED_LIST = ''

// export const LIGHTLINK_PEGASUS_LIST = 'https://ipfs.io/ipfs/QmPe2bcHw93cSFg5ogdGBakK6yBBgREd6hmr594dphz2Pe/'
// export const LIGHTLINK_PHOENIX_LIST = 'https://ipfs.io/ipfs/QmdtsuxFmKNV6sKqgvLNGpjViz5FwpZjV7zTfAQhFa1upk/'

export const LIGHTLINK_PEGASUS_LIST = 'https://ipfs.io/ipfs/QmUig8pfG2ne3bhcdPq5qWmSvUSBkNCrk4Yon7M4h3HwTz'

// export const LIGHTLINK_PEGASUS_LIST = 'https://ipfs.io/ipfs/QmdsQVGJ6g19qTUDwiTfENjw65uDuPyVWdegVxh454hx8J'
export const LIGHTLINK_PHOENIX_LIST = 'https://ipfs.io/ipfs/Qmbq8creG8Gw9x4qLK3m9WFcYY99dJv35rZHFT6jZ6bi8b/'

export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
export const PLASMA_BNB_LIST = 'https://raw.githubusercontent.com/plasmadlt/plasma-finance-token-list/master/bnb.json'
//Change, add token list here
export const UNSUPPORTED_LIST_URLS: string[] = [
  UNI_UNSUPPORTED_LIST,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [UNI_LIST, LIGHTLINK_PEGASUS_LIST, LIGHTLINK_PHOENIX_LIST]
//! donot remove all list - it will break
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [
  UNI_EXTENDED_LIST,

  ...UNSUPPORTED_LIST_URLS,
]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]



export const BASE_URL = 'https://elektrik.network/'


